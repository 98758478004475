export const environment = {
  production: false,
  firebase: {
    apiKey: import.meta.env["NG_APP_FIREBASE_API_KEY"],
    authDomain: "qa-portal-clientes.firebaseapp.com",
    projectId: "qa-portal-clientes",
    storageBucket: "qa-portal-clientes.appspot.com",
    messagingSenderId: "335658957333",
    appId: "1:335658957333:web:7c35dff6c4547fa7102c1c"
  },
  apinestjs: "https://qa-api-portalclientes.divemotor.com",
  //apinestjs: "http://localhost:3001",
  apimanagement:{
    endpoint: "https://apimazqa.grupokaufmann.com/divemotor/deuda",
    apiKey: "9ec237a4569c4c73860d896b64b09dee",
    endpointbonos: "https://apimazqa.grupokaufmann.com/qas/v1/bonos",
    apiKeybonos: "0689028078a84e26be0b58b98a61c1b6",
    //endpointtracking: "https://apimazqa.grupokaufmann.com/qas/v1/sap/consultahitos",
    //apiKeytracking: "8aa01c9540694a3da3b807541e9ce939",
    endpointtracking: "https://apimaz.grupokaufmann.com/prd/v1/sap/consultahitos",
    apiKeytracking: "c6b4aa206d9a4e9380927df09a7297f1",
  }
};