import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //For register route
    if(request.url.includes('users/register'))
      return next.handle(request)

    //For addBonos route
    if(request.url.includes('users/add-bono'))
      return next.handle(request)

    //For validate user route
    if(request.url.includes('users/validate'))
    return next.handle(request)

    //For captcha route
    if(request.url.includes('users/captcha-validate'))
    return next.handle(request)

    
    //For Api Management Routes
    if(request.url.includes('apimaz')){

      let apiKey = "";
      if(request.url.includes('bonos')){
          apiKey = environment.apimanagement.apiKeybonos;
      }
      if(request.url.includes('deuda')){
        apiKey = environment.apimanagement.apiKey;
      }

      if(request.url.includes('consultahitos')){
        apiKey = environment.apimanagement.apiKeytracking;
      }

      let clone = request.clone();
      clone = clone.clone({ headers: request.headers.set('Ocp-Apim-Subscription-Key', apiKey ).set('X-REQUESTED-WITH','X')});
      return next.handle(clone)
    }

    
    let currentUser =  localStorage.getItem('user');
    let currentUserData = JSON.parse(currentUser);
    
    let clone = request.clone();
    let idToken = currentUserData.stsTokenManager.accessToken;
    
    if (idToken) {
        clone = clone.clone({ headers: request.headers.set('Authorization', 'Bearer ' + idToken) });
    }
    return next.handle(clone)
  }
}

export const AuthTokenHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenInterceptor,
  multi: true
}
