import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import{ Router, NavigationEnd} from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  isCollapsed = false;
  iconUrl = '';

  favIcon: HTMLLinkElement = document.querySelector('#appIcon'); 
  appTitle: HTMLTitleElement = document.querySelector('#appTitle'); 

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
      //Change Title based on Enviroment
      if(!environment.production){
        this.appTitle.text = "QA Portal de Clientes";
      }
      else{
        this.setUpAnalytics();
      }

      //Change Icon Based on URL Divemotor/Andesmotor
      this.iconUrl = window.location.href.includes('andesmotor') ? 'assets/icons/andesmotor-style.ico':'assets/icons/divemotor-style.ico';
      this.favIcon.href = this.iconUrl;
   }



   setUpAnalytics() {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'G-CRF7EN9Y9K', {
          'page_path': event.urlAfterRedirects,
          'page_title': event.urlAfterRedirects
        });
       }
    })
    }
}
