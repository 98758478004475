<nz-layout class="app-layout" [ngClass]="currentURL">
    <nz-sider class="menu-sidebar"
              nzWidth="215px"                          
              [nzBreakpoint]="isMobile ? 'xxl' : 'xs'"
              [(nzCollapsed)]="isCollapsed"
              [nzTrigger]="null"
              [ngClass]="{'menu-sidebar-mobile': isMobile}">
              
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">

        <li nz-menu-item nzMatchRouter nzTitle="Deuda Consolidada" nzIcon="gift" *ngIf="authService.getCurrentUserAccounts().length > 0 || authService.userHasModule('deuda')">
          <div class="menu-item-center">
            <a routerLink="/search" ><i nz-icon nzType="dollar"></i>
              <span>Deuda Consolidada</span></a>
          </div>
        </li>
        
        <li nz-menu-item nzMatchRouter nzTitle="Bonos" nzIcon="gift" *ngIf="authService.getCurrentUserBonos().length > 0 || authService.userHasModule('bonos')">
          <div class="menu-item-center">
            <a routerLink="/bonos" ><i nz-icon nzType="gift"></i>
            <span>Bonos</span></a>
          </div>
        </li>

        <li nz-menu-item nzMatchRouter nzTitle="Tracking" nzIcon="gift" *ngIf="authService.userHasModule('tracking')">
          <div class="menu-item-center">
            <a routerLink="/tracking" ><i nz-icon nzType="node-index"></i>
            <span>Tracking</span></a>
          </div>
        </li>    

        <li class="sub-menu-item" nz-submenu nzOpen nzTitle="Autenticación" nzIcon="user" *ngIf="userRole=='admin'">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/roles">Roles </a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/users">Usuarios</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/clients">Clientes</a>
            </li>
          </ul>
        </li>
        
        <li class="sub-menu-item" nz-submenu nzOpen nzTitle="Contacto" nzIcon="contacts" *ngIf="userRole=='admin'">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/contacts">Directorio </a>
            </li>
          </ul>
        </li>
        <li
          nz-menu-item
          nzTitle="Manual"
          *ngIf="authService.getCurrentUserAccounts().length > 0"
        >
          <i nz-icon nzType="question-circle"></i>
          <span><a download="Manual Cliente - Portal de Clientes.pdf" target="_blank" href="/assets/files/manual-cliente.pdf"> Manual de uso</a></span>
        </li>
        <li
          nz-menu-item
          nzTitle="Salir"
          (click)="authService.SignOut()"
        >
        <div class="menu-item-center">
          <i nz-icon nzType="logout"></i>
          <span>Salir</span>
        </div>
        </li>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header">
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
              <i class="trigger"
                 nz-icon
                 [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                 style="color:white"
              ></i>
          </span>
          <span style="color:white;font-size:20px;font-family: 'Roboto', sans-serif;font-weight: 400;">
          <!--Divemotor-->
            <img class="logo-fold" src="assets/logo-divemotor-2.svg" alt="Logo Divemotor" *ngIf="currentURL==='divemotor-style'">

            <!--Andesmotor-->
            <img class="logo-fold" src="assets/logo-andesmotor.svg" alt="Logo Andesmotor" *ngIf="currentURL==='andesmotor-style'">
          </span>
          <span style="float:right" *ngIf="authService.userData as user">

          <nz-avatar 
            [nzPopoverContent]="contentTemplate"
            nz-button
            nz-popover
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottomRight"
            nzText="{{ user.email.charAt(0).toUpperCase() }}" style="margin-right:10px"           
            nzTooltipPlacement="left" 
            nz-button nz-tooltip            
            class="avatar-style">
          </nz-avatar>
          <ng-template #contentTemplate>         
            <p style="font-size: 10px;">{{ user.email.split('@')[0].toUpperCase() }}</p>
            <a class="template-btton-close" (click)="authService.SignOut()"><i nz-icon nzType="logout"></i> Salir</a>
          </ng-template>
<!---->

          </span>
        </div>
        
      </nz-header>
      <nz-content (click)="allClick()">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
  
  