import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public messageService: NzMessageService,
    public router: Router,
    public afAuth: AngularFireAuth, 
  ){ }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let data = next.data;

    if(this.authService.isLoggedIn !== true) {
      this.router.navigateByUrl('auth/sign-in');
      return false;
    }
    else{
      if( this.authService.userHasModule(data['module'])){
        return true;
      }
      else{
        let fmp = this.authService.userFirtsModulePath();
        this.router.navigateByUrl(fmp);
        return false;
      }
    }
    
  }
}