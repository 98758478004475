import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../shared/models/user';

export type RegisterUserRequest = { names: string, surnames: string, email: string, accounts: any[]};
export type RegisterBonosUserRequest = { names: string, surnames: string, email: string,password: string, bonos: any[]};
export type AddBonosUserRequest = { email: string, bono: any };
export type CreateUserRequest = { role: string,notifications: string, status: string,names: string, surnames: string, email: string, accounts: any[], bonos: any[] };
export type UpdateUserRequest = { uid: string ,role: string,notifications: string,status: string,names: string, surnames: string, email: string, accounts: any[], bonos: any[]};
export type DeleteUserRequest = { uid: string };

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = environment.apinestjs;
  
  constructor(
    private http: HttpClient
  ) { }

 
  get users$(): Observable<User[]> {
    return this.http.get<{ users: User[] }>(`${this.baseUrl}/users`).pipe(
      map(result => {
        return result.users
      })
    )
  }

  register(user: RegisterUserRequest) {
    return this.http.post(`${this.baseUrl}/users/register`, user)
  }

  registerBonos(user: RegisterBonosUserRequest) {
    return this.http.post(`${this.baseUrl}/users/register-bonos`, user)
  }

  addBono(newBono: AddBonosUserRequest) {
    return this.http.post(`${this.baseUrl}/users/add-bono`, newBono)
  }

  create(user: CreateUserRequest) {
    return this.http.post(`${this.baseUrl}/users`, user)
  }

  getCustomToken(uid): Observable<any> {
    return this.http.get<{ user: any }>(`${this.baseUrl}/users/validate/${ encodeURIComponent(uid) }`)
    .pipe(
      map(result => {
        return result
      })
    )
  }


  edit(user: UpdateUserRequest) {
    return this.http.patch(`${this.baseUrl}/users/${user.uid}`, user)
  }

  logout(uid) {
    return this.http.get(`${this.baseUrl}/users/logout/${uid}`)
  }

  delete(user: DeleteUserRequest) {
    return this.http.delete(`${this.baseUrl}/users/${user.uid}`)
  }

}
