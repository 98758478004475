import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isMobile = false;
  isCollapsed = true;
  currentURL = '';
  userRole = 'user';
  constructor(
    public authService: AuthService
  ) { 
    this.currentURL = window.location.href.includes('andesmotor') ? 'andesmotor-style':'divemotor-style';
    this.userRole = authService.getCurrentUserRole();
  }

  ngOnInit(): void {
    this.closeMobileMenu();
  }


  allClick(){
    if(this.isMobile && !this.isCollapsed) {
      this.isCollapsed = true;
    }
  }


  closeMobileMenu(): void {
    this.isMobile = window.innerWidth <= 770;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closeMobileMenu();
  }

}
